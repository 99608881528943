
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const formData = ref({
      newpassword: "",
      confirmpassword: "",
    });
    const store = useStore();
    const loading = ref<boolean>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const router = useRouter();
    const route = useRoute();
    //Create form validation object
    const forgotPassword = Yup.object().shape({
      newpassword: Yup.string().min(4).required().label("Password"),
      confirmpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      console.log("ddf", values);
      // dummy delay
      // setTimeout(() => {
      loading.value = true;
      var id = route.params.id;
      var request = {
        url: "newPasswordSet",
        data: {
          newpassword: values.newpassword,
          confirmpassword: values.confirmpassword,
          user_id: id,
        },
      };
      store
        .dispatch(Actions.FORGOT_PASSWORD, request)
        .then((data) => {
          if (data) {
            Swal.fire({
              text: "Password reset successfully!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "sign-in" });
            });
          }
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          // Alert then login failed
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      // }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      formData,
    };
  },
});
